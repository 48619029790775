<script>
import { Bar } from "vue-chartjs";
import Chart from "chart.js";
import ChartAnnotationsPlugin from "chartjs-plugin-annotation";
Chart.plugins.register(ChartAnnotationsPlugin);

export default {
  extends: Bar,
  name: "RainChart",
  props: {
    currentJst: String,
    timeSeries: [Object, Array],
    displayInterval: Number
  },
  data() {
    return {
      dataCollection: {
        labels: [],
        datasets: [
          {
            type: "line",
            label: "累加雨量",
            unit: "mm",
            pointBackgroundColor: "white",
            fill: false,
            yAxisID: "totalRain",
            xAxisID: "data",
            borderWidth: 2,
            borderColor: "#91C46C",
            backgroundColor: "#91C46C",
            pointBorderColor: "#91C46C",
            pointRadius: 2,
            pointHitRadius: 0,
            lineTension: 0,
            data: [],
            spanGaps: true
          },
          {
            type: "bar",
            label: "10分雨量",
            yAxisID: "rain",
            xAxisID: "data",
            unit: "mm",
            backgroundColor: "#1C344C",
            tooltipLabelColor: "#1C344C",
            borderColor: "#1C344C",
            pointBackgroundColor: "white",
            borderWidth: 0.5,
            data: [],
            barPercentage: 1.0,
            categoryPercentage: 1.0
          },
          {
            type: "line",
            label: "累加雨量",
            unit: "mm",
            pointBackgroundColor: "91C46C",
            fill: false,
            yAxisID: "totalRain",
            xAxisID: "data",
            borderWidth: 3,
            borderColor: "#91C46C",
            backgroundColor: "#91C46C",
            pointBorderColor: "#91C46C",
            borderDash: [5, 2],
            pointRadius: 0,
            pointHitRadius: 0,
            lineTension: 0,
            data: [],
            spanGaps: true
          },
          {
            type: "bar",
            label: "10分雨量",
            yAxisID: "rain",
            xAxisID: "data",
            unit: "mm",
            backgroundColor: "#7294b8",
            tooltipLabelColor: "#7294b8",
            borderColor: "#7294b8",
            pointBackgroundColor: "white",
            borderWidth: 0.5,
            data: [],
            barPercentage: 1.0,
            categoryPercentage: 1.0
          }
        ]
      },
      options: {
        layout: {
          padding: {
            right: 0
          }
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
          callbacks: {
            label: function(tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label;
              const unit = data.datasets[tooltipItem.datasetIndex].unit;
              const value =
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              if (value === null) {
                return;
              }
              return label + " : " + value + " " + unit;
            }
          }
        },
        scales: {
          yAxes: [
            {
              id: "totalRain",
              position: "right",
              gridLines: {
                display: true
              },
              ticks: {
                max: 20,
                min: 0,
                stepSize: 20,
                callback: label => {
                  return parseFloat(label).toFixed(0);
                }
              }
            },
            {
              id: "rain",
              position: "left",
              gridLines: {
                display: false
              },
              ticks: {
                max: 20,
                min: 0,
                stepSize: 1,
                callback: label => {
                  return parseFloat(label).toFixed(1);
                }
              }
            }
          ],
          xAxes: [
            {
              id: "data",
              gridLines: {
                display: false
              },
              ticks: {
                display: true,
                autoSkip: true,
                callback: tick => {
                  return tick.substr(11, 5);
                }
              }
            }
          ]
        },
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          mode: "index",
          intersect: false
        },
        annotation: {
          events: ["click", "dblclick", "mouseover", "mouseout"],
          annotations: [
            {
              type: "line",
              mode: "vertical",
              scaleID: "data",
              value: "02:00",
              borderColor: "#FF0000",
              borderWidth: 1.5,
              label: {
                content: "現在",
                position: "top",
                enabled: true
              }
            }
          ]
        },
        legend: {
          display: false
        }
      }
    };
  },
  watch: {
    timeSeries() {
      this.setTimeSeries();
      this.setYAxesLimitData();
      this.renderChart(this.dataCollection, this.options);
    }
  },
  mounted() {
    this.setTimeSeries();
    this.setYAxesLimitData();
    setTimeout(() => {
      this.renderChart(this.dataCollection, this.options);
    }, 500);
  },
  methods: {
    setTimeSeries() {
      // timeseries
      this.dataCollection.labels = this.timeSeries.map(ts => ts.jst);

      // total
      this.dataCollection.datasets[0].data = this.timeSeries.map(ts =>
        ts.jst <= this.currentJst ? ts.total : null
      );
      this.dataCollection.datasets[2].data = this.timeSeries.map(ts =>
        ts.jst > this.currentJst ? ts.total : null
      );

      // min10 or min60
      this.dataCollection.datasets[1].data = this.timeSeries.map(ts =>
        ts.jst <= this.currentJst ? ts.value : null
      );
      this.dataCollection.datasets[3].data = this.timeSeries.map(ts =>
        ts.jst > this.currentJst ? ts.value : null
      );
      this.dataCollection.datasets[1].label = `${this.displayInterval}分雨量`;
      this.dataCollection.datasets[3].label = `${this.displayInterval}分雨量`;

      // current datetime
      this.options.annotation.annotations[0].value = this.currentJst;
    },
    setYAxesLimitData() {
      const values = this.timeSeries.map(t => t.value).filter(v => v !== null);
      const maxLevel = Math.max(...values);

      const totals = this.timeSeries.map(t => t.total).filter(v => v !== null);
      const totalMaxLevel = Math.max(...totals);

      this.options.scales.yAxes[0].ticks.min = 0;
      this.options.scales.yAxes[0].ticks.max =
        totalMaxLevel < 100 ? 100 : Math.floor(totalMaxLevel + 20);

      this.options.scales.yAxes[1].ticks.min = 0;
      this.options.scales.yAxes[1].ticks.max =
        maxLevel < 5 ? 5 : Math.floor(maxLevel + 2);
    }
  }
};
</script>

<style scoped></style>
