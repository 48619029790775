<template>
  <div></div>
</template>
<script>
import L from "leaflet";
import "leaflet-markers-canvas";
import axios from "axios";
import { mytimelineUrl } from "../../../utils/common";

const TARGET_ZOOM = 13;

export default {
  name: "ShelterLayer",
  data() {
    return {
      points: [],
      markers: [],
      layer: null,
      layerName: "shelter-layer",
      popup: L.popup({ maxWidth: 320 })
    };
  },
  computed: {
    map() {
      return this.$store.getters.leafletMap;
    },
    zoom() {
      return Math.floor(this.map.getZoom());
    },
    visibleShelter() {
      return this.$store.getters.visibleShelter;
    },
    isMyTimelineShelter() {
      return (
        this.$route.query.mytimeline_shelter &&
        this.$route.query.mytimeline_shelter === "1"
      );
    }
  },
  watch: {
    zoom(newval, oldval) {
      if (Math.floor(newval) === Math.floor(oldval)) {
        return;
      }
      if (newval < TARGET_ZOOM) {
        this.clear();
        this.redraw();
      } else {
        this.load();
      }
    },
    visibleShelter(newval, oldval) {
      if (newval !== oldval) {
        if (newval === true) {
          this.load();
        } else {
          this.clear();
          this.redraw();
        }
      }
    }
  },
  created() {
    if (this.map) {
      this.layer = new L.MarkersCanvas();
      this.layer.id = this.layerName;
      this.layer.addTo(this.map);
    }
  },
  mounted() {
    if (this.map) {
      this.load();
    }
  },
  beforeDestroy() {
    this.clear();
    if (this.layer) {
      this.layer.remove();
    }
  },
  methods: {
    clear() {
      if (this.layer) {
        this.layer.removeMarkers(this.markers);
        this.layer.clear();
      }
      this.markers = [];
    },
    redraw() {
      this.layer.redraw();
      this.map.panTo(this.map.getCenter());
    },
    async syncPoints() {
      this.points = [];
      const response = await axios
        .create({
          responseType: "json"
        })
        .get(
          "https://d1gpfmzkl8v4u5.cloudfront.net/static/hinanjo/10000_1_sitei_hinanjo.geojson"
        );
      if (response === null || response.data === null) {
        return;
      }
      for (const feature of response.data.features) {
        const coordinates = feature.geometry.coordinates;
        const properties = feature.properties;
        if (properties["受入対象者"] !== null) {
          continue;
        }
        let content = `<div id="popup-shelter">`;
        content += `<div style="font-weight: bold">指定避難所</div>`;
        content += `<div>施設・場所名: ${properties["施設・場所名"]}</div>`;
        content += `<div>住所: ${properties["住所"]}</div>`;
        if (this.isMyTimelineShelter === true) {
          content += `<div id="popup-shelter-button">この場所を選択する</div>`;
        }
        content += "</div>";
        this.points.push({
          lat: coordinates[1],
          lng: coordinates[0],
          name: properties["施設・場所名"],
          content: content
        });
      }
    },
    async load() {
      this.clear();
      if (this.visibleShelter !== true || this.zoom < TARGET_ZOOM) {
        return;
      }
      if (this.points.length === 0) {
        await this.syncPoints();
      }

      const openShelterPopup = point => {
        this.popup
          .setLatLng(L.latLng([point.lat, point.lng]))
          .setContent(point.content)
          .openOn(this.map);
        if (this.isMyTimelineShelter !== true) {
          return;
        }
        const popupShelterButton = document.getElementById(
          "popup-shelter-button"
        );
        popupShelterButton.onclick = async () => {
          if (window.opener === undefined || window.opener == null) {
            return;
          }
          const message = {
            name: point.name,
            lat: point.lat,
            lng: point.lng
          };

          window.opener.postMessage(message, mytimelineUrl);
          setTimeout(() => {
            window.close();
            window.opener.focus();
          }, 500);
        };
      };

      const iconSize = 24;
      this.points.forEach(p => {
        const iconUrl = require("@/assets/images/png/shelter.png");
        const iconAnchor = [iconSize * 0.5, iconSize * 0.5];
        const icon = L.icon({
          iconSize: [iconSize, iconSize],
          iconAnchor: iconAnchor,
          iconUrl: iconUrl
        });

        const latLng = L.latLng([p.lat, p.lng]);
        const marker = L.marker(latLng, {
          icon: icon
        }).on({
          click() {
            openShelterPopup(p);
          }
        });
        this.markers.push(marker);
      });
      this.layer.addMarkers(this.markers);
      this.redraw();
    }
  }
};
</script>

<style>
.leaflet-marker-icon {
  image-rendering: inherit;
}
</style>
